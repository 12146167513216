<!-- 多级跳转 -->
<style lang="scss" scoped>
// .hover_box{
//     position: absolute;
//     left: 20%;
//     top:-50%;
//     max-width: 200px;
//     // height: 50px;
//     border-radius: 5px;
//     background: #F7F7F7 !important;
//     color: #303133 !important;
//     box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
//     padding: 10px ;
//     box-sizing: border-box;
//     z-index: 1110;
//     .hover_box_text{
//         font-weight: bold;
//     }
// }
.popover_box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    &_text {
        text-align: center;
        margin-bottom: 5px;
        font-weight: bold;
        background: linear-gradient(180deg, #DBE7FF 2%, rgba(255, 255, 255, 0) 100%);
        padding: 5px 12px;
    }

    &_textAll {
        font-size: 12px;
        margin: 0 0 10px 0;
        padding: 0px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.el-popover {
    padding: 0 !important;
    min-width: 200px !important;
}

.custom-popover {
    min-width: 0 !important;
    max-width: 200px;
}
</style>
<template>
    <div style="display: contents;">
        <template v-if="name === 'component' && obj.natureName">
            <el-popover ref="popover" placement="top" width="200" :trigger="'hover'"
                :popper-class="isHovering ? 'custom-popover' : ''">
                <div class="popover_box">
                    <template v-if="isHovering">
                        <div class="popover_box" style="font-size: 10px;color: #F11C1C;">
                            <span class="popover_box_text" style="margin-bottom:unset;">
                                {{ obj.name }}：
                                <span style="font-weight: normal;">{{ obj.natureName }}</span>
                            </span>

                        </div>
                    </template>
                    <template v-else>
                        <span class="popover_box_text"> {{ obj.name }}</span>
                        <div class="popover_box_textAll" v-for="(item, index) in btnList" :key="index">
                            <span>
                                <i :class="item.icon" style="color: #5171DA;"></i>
                                {{ item.text }}
                            </span>
                            <span @click="clickButton(item, obj)" style="color: #5171DA;cursor: pointer;">
                                跳转
                            </span>
                        </div>
                    </template>
                </div>
                <span slot="reference" :style="{ color: obj.natureName ? '#F11C1C' : '#5171DA', }"
                    style="cursor: pointer;" @mouseenter="onMouseEnter" @click="clickSpan">
                    {{ obj.name }}</span>
            </el-popover>
        </template>
        <template v-else>
            <el-popover ref="popover" placement="top" width="200">
                <div class="popover_box">
                    <span class="popover_box_text"> {{ obj.commonName || obj.name }}</span>
                    <div class="popover_box_textAll" v-for="(item, index) in btnList" :key="index">
                        <span>
                            <i :class="item.icon" style="color: #5171DA;"></i>
                            {{ item.text }}
                        </span>
                        <span @click="clickButton(item, obj)" style="color: #5171DA;cursor: pointer;">
                            跳转
                        </span>
                    </div>
                </div>
                <span slot="reference" style="cursor: pointer;color: #5171DA;">{{ obj.commonName || obj.name }}</span>
            </el-popover>
        </template>
    </div>

</template>

<script>
export default {
    props: {
        obj: {
            default: Object,
            typeof: () => { }
        },
        btnList: {
            default: Array,
            typeof: () => []
        },
        name: {
            default: String,
            typeof: '',
        },

    },
    data() {
        return {
            isHovering: false,
        }
    },
    methods: {
        clickSpan() {
            if (!this.isHovering) return false
            let top = this.$refs.popover.popperElm.style.top.replace(/px/g, '')
            this.isHovering = false
            this.$refs.popover.popperElm.style.top = Number(top) - 65 + 'px'
        },
        //鼠标移入
        onMouseEnter() {
            this.isHovering = true
        },
        //点击按钮
        clickButton(row, val) {
            console.log('点击跳转', row, val);
            if (row.componentContrast || row.indicationContrast) {
                let str = row.componentContrast ? 'componentData' : 'indicationData'
                if(val.name){
                    this.$store.commit('setCombinationObjShow',true)
                    this.$store.commit('setCombinationObj',{name:val.name,str,title:row.title})
                }
                this.$refs.popover.doClose()
                console.log('加入对比',val,this.$store.state.combinationObj[str]);
            } else {
                let obj = {}
                if (row.params && row.params.length != 0) {
                    row.params.forEach(item => {
                        let key = Object.keys(item)[0]
                        obj[key] = val[item[key]]
                    });
                }
                if (row.url === '/ch-medicinal-price' && (!val.chineseMedicinalCode || !val.chineseMedicinalName)) {
                    return this.$message.warning('暂无此成分基本信息！')
                }
                this.goPage(row.url, obj)
            }
            // let obj = {}
            // if (row.params && row.params.length != 0) {
            //     row.params.forEach(item => {
            //         let key = Object.keys(item)[0]
            //         obj[key] = val[item[key]]
            //     });
            // }
            // if (row.url === '/ch-medicinal-price' && (!val.chineseMedicinalCode || !val.chineseMedicinalName)) {
            //     return this.$message.warning('暂无此成分基本信息！')
            // }
            // this.goPage(row.url, obj)
        },
        //跳转页面
        goPage(url, obj) {
            const { href } = this.$router.resolve({
                path: url,
                query: obj
            });
            window.open(href, "_blank");
        },
    },
}
</script>