<style lang="scss">
@import "~@/assets/css/var";

.table-operate {
    position: absolute;
    top: -5%;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #F7F7F7;
    .txt-btn {
        color: #666;
        font-size: 14px;
        margin-right: 20px;

        &:hover {
            color: $main;
        }
    }

    .el-dropdown {
        margin: 0 20px;
        font-weight: 500;
        line-height: 30px;
        cursor: pointer;
        font-size: 14px;
        color: #666;

        &:hover {
            color: $main;
        }
    }

    &__dropdown {
        margin: 0 !important;

        .my-table {
            padding: 0;
        }
    }

    &__line {
        width: 1px;
        height: 26px;
        background: #D4D4D4;
    }
}

$border: #DCDFE6;

.advance-search {
    display: flex;
    padding: 10px 0;

    &__total {
        border-right: 1px solid $border;
        align-content: stretch;
        margin: 24px 0;
        display: flex;

        p {
            align-self: center;
            padding-right: 12px;
        }
    }

    &__panel {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__item {
        display: flex;
        flex: 1;
        margin: 8px 0;
        align-items: center;

        .el-icon-remove {
            font-size: 24px;
            margin-left: 12px;
            color: #5B5B5B;
            cursor: pointer;

            &:hover {
                color: $red;
            }
        }
    }

    &__field {
        display: flex;
        align-items: center;
        flex: 1;

        .el-select {
            flex: 1;
        }

        &-line {
            width: 40px;
            height: 1px;
            background: $border;
        }
    }

    &__value {
        flex: 1;
        margin-left: 6px;

        &>* {
            width: 100%;
        }
    }

    &__assist {
        display: flex;
        flex: 1;
        align-items: center;
        line-height: 32px;
        font-size: 14px;
        font-weight: 400;
        color: #999;
        cursor: pointer;

        i {
            font-size: 24px;
            color: $main;
            margin: 0 8px 0 3px;
        }

        &:hover {
            opacity: .7;
        }
    }

    .el-input__inner {
        border-color: $border;
        border-radius: 0;
        font-size: 14px;
        line-height: 32px;
        height: 32px;
    }
}

.el-popover {
    padding: 0 !important;
    min-width: 0 !important;
    // max-width: 200px;
    font-size: 12px;
}
</style>

<template>
    <div class="table-operate" :style="styleText">
        <slot name="left"></slot>
        <template v-if="(name === 'drugProduct' || name === 'corpProduct') && $help.token.get()">
            <el-popover placement="left" trigger="hover" width="200">
                <p style="margin: 0 10px;">勾选查看关注药品</p>
                <i slot="reference" class="el-icon-info popup-class__i"
                    style="color: #45d75f;font-size: 16px;margin: 6px 6px 0 0;"></i>
            </el-popover>
            <el-checkbox class="txt-btn" v-model="unFollowed" @change="clickFollowed">
                {{ unFollowed ? '已关注药品' : '全部药品' }}</el-checkbox>
        </template>
        <template v-if="['drugInstruction', 'du_instruction_books'].includes(name)">
            <el-popover placement="left" trigger="hover" width="200">
                <p style="margin: 0 10px;">成分为有毒、有害、易燃、易爆、保护动植物等</p>
                <span slot="reference" style="color: #F11C1C;font-size: 12px;margin: 6px 20px 0 0;">
                    <i class="el-icon-info popup-class__i" style="color: #F11C1C;font-size: 16px;"></i>
                    红色</span>
            </el-popover>
        </template>

        <el-button class="txt-btn" v-show="showExport" type="text" icon="el-icon-document"
            @click="$emit('leadingOut')">导出</el-button>
        <div class="table-operate__line" v-show="!hideSearch && showExport" style="margin-right: 20px;"></div>
        <el-button class="txt-btn" v-show="!hideSearch" type="text" icon="el-icon-search"
            @click="searchShow">筛选</el-button>
        <div class="table-operate__line" v-show="realCols.length > 0 && name" style="margin-right: 20px;"></div>
        <el-button class="txt-btn" v-show="realCols.length > 0 && name" type="text" icon="el-icon-setting"
            @click="showSet">栏目</el-button>
        <slot></slot>
        <ts-dialog :show.sync="dialog.show" title="筛选" width="600px" is-cancel-event
            @cancel="dialog.list = [{ model: '', field: {} }]" @save="dialogSave" sure-txt="筛选" cancel-txt="清除筛选值"
            sure-icon="" cancel-icon="">
            <!--<template #footLeft>-->
            <!--<el-button v-show="isExport" style="float:left;" @click="leadingOut">导出</el-button>-->
            <!--</template>-->

            <template>
                <div class="advance-search">
                    <div class="advance-search__total">
                        <p>列项</p>
                    </div>
                    <div class="advance-search__panel">
                        <div class="advance-search__item" v-for="(item, i) in dialog.list " :key="'advance' + i">
                            <div class="advance-search__field">
                                <div class="advance-search__field-line"></div>
                                <el-select v-model="item.field" filterable placeholder="请选择" value-key="id"
                                    @change="changeModel(item)">
                                    <el-option v-for="option in dialog.cols" :key="option.id" :value="option"
                                        :label="option.viewName || option.name"></el-option>
                                </el-select>
                            </div>
                            <div class="advance-search__value">
                                <template v-if="item.field">
                                    <el-input v-if="item.field && !item.field.id" disabled
                                        :placeholder="'请输入' + (item.field.viewName || item.field.name || '')"></el-input>

                                    <!--模糊搜索-->
                                    <template v-else-if="item.field.listQueryModel === $variable.searchType.LIKE">
                                        <el-input v-model="item.model"
                                            :placeholder="'请输入' + (item.field.viewName || item.field.name || '')"></el-input>
                                    </template>
                                    <!--精确搜索-->
                                    <template v-else-if="item.field.listQueryModel === $variable.searchType.EQ">
                                        <el-input v-model="item.model"
                                            :placeholder="'请输入' + (item.field.viewName || item.field.name || '')"></el-input>
                                    </template>

                                    <!--下拉选择性-->
                                    <template v-else-if="item.field.listQueryModel === $variable.searchType.SELECT">
                                        <el-select v-model="item.model" :multiple="!item.field.single" filterable remote
                                            style="width:100%"
                                            :placeholder="'请选择' + (item.field.viewName || item.field.name || '')"
                                            @focus="selectRemote(item.field, undefined, 'focus')" reserve-keyword
                                            :remote-method="(argument) => { selectRemote(item.field, argument) }"
                                            :loading="item.field.loading">
                                            <el-option v-for="(child, key) in item.field.list" :key="key"
                                                :value="item.field.useKey ? child.key : child.value"
                                                :label="child.value"></el-option>
                                        </el-select>
                                    </template>

                                    <!--数字范围-->
                                    <template v-else-if="item.field.listQueryModel === $variable.searchType.NUM">
                                        <div style="display: flex;align-items: center;">
                                            <el-input-number v-model="item.model[0]" :min="0"
                                                @change="clickNumber(item.model)"></el-input-number>
                                            ~
                                            <el-input-number v-model="item.model[1]"
                                                :min="item.model[0] || 0"></el-input-number>
                                        </div>

                                    </template>

                                    <!--日期范围-->
                                    <template v-else-if="item.field.listQueryModel === $variable.searchType.DATE">
                                        <el-date-picker style="width:100%" v-model="item.model" type="daterange"
                                            range-separator="至" value-format="yyyy-MM-dd"
                                            unlink-panels></el-date-picker>
                                    </template>
                                </template>

                            </div>
                            <i class="el-icon-remove" @click="dialog.list.splice(i, 1)"></i>
                        </div>
                        <div class="advance-search__item">
                            <div class="advance-search__field">
                                <div class="advance-search__field-line"></div>
                                <div class="advance-search__assist" @click="dialog.list.push({ model: '', field: {} })">
                                    <i class="el-icon-circle-plus"></i>
                                    <p>添加多列项筛选</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </ts-dialog>

        <ts-dialog :show.sync="set.show" title="栏目设置" is-cancel-event cancel-icon="el-icon-refresh-right"
            cancel-txt="系统设置" @save="setSave" @cancel="setCancel" width="800px">
            <div class="table-operate__dropdown">
                <div class="my-table" v-if="set.loaded">
                    <el-table ref="tableRef" :data="realCols" border stripe tooltip-effect="light" :height="400">
                        <el-table-column fixed type="index" width="50" label="#" align="center"></el-table-column>
                        <el-table-column width="150" label="栏目名称" align="center">
                            <template slot-scope="{row}">{{ row.viewName || row.name }}</template>
                        </el-table-column>
                        <el-table-column width="80" label="是否显示" align="center">
                            <template slot-scope="{row}">
                                <el-checkbox v-model="row.listViewSelf" true-label="是" false-label="否"></el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="150" label="是否固定" align="center">
                            <template slot-scope="{row}">
                                <el-select v-model="row.listStipulate" placeholder="请选择">
                                    <el-option label="不固定" :value="0"></el-option>
                                    <el-option label="固定左侧" :value="1"></el-option>
                                    <el-option label="固定右侧" :value="2"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="150" label="文字大小" align="center">
                            <template slot-scope="{row}">
                                <el-select v-model="row.fontSize" placeholder="请选择大小">
                                    <el-option label="8px" :value="8"></el-option>
                                    <el-option label="10px" :value="10"></el-option>
                                    <el-option label="12px" :value="12"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="160" label="宽度" align="center">
                            <template slot-scope="{row}">
                                <el-input-number v-model="row.width" step-strictly></el-input-number>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </ts-dialog>
    </div>
</template>

<script>
// import Sortable from 'sortablejs'
export default {
    props: {
        parent: {},
        cols: {},
        name: {},
        tableName: {},
        isExport: {
            type: Boolean,
            default: false,
        },
        hideSearch: {
            type: Boolean,
            default: false,
        },
        showExport: {
            type: Boolean,
            default: false,
        },
        hideSort: {
            type: Boolean,
            default: false,
        },
        styleText: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            sortKey: 'view' + this.name,
            sortCols: [],
            checkSort: [],

            checkShow: [],
            realCols: [],
            realColsAfter: [],

            dialog: {
                show: false,
                cols: [],
                list: [
                    {
                        model: '',
                        field: {}
                    },
                ],
            },

            set: {
                show: false,
                loaded: true,
            },
            unFollowed: false,
        }
    },
    created() {
        this.sortKey = 'view' + this.name
        this.computedCols()
        this.computedDialogCols()
    },
    methods: {
        clickNumber(val) {
            if (val[1] < val[0]) val[1] = val[0]
        },
        changeModel(item) {
            if (item.field.listQueryModel === this.$variable.searchType.NUM) {
                item.model = ['', '']
            } else {
                item.model = ''
            }
        },
        clickFollowed(val) {
            this.$emit('clickFollowed', val);
        },
        computedCols() {
            if (this.cols) {
                const cols = this.$help.deepClone(this.cols)
                let list = cols.filter(item => item.listView === '是')

                const setList = this.$help.store.get(this.sortKey)
                this.realCols = list
                    .sort((a, b) => {
                        return a.listSort - b.listSort;
                    })
                    .map(item => {
                        this.$set(item, 'viewName', item.viewName ? item.viewName.replace(/\\n/g, '') : '')
                        this.$set(item, 'name', item.name ? item.name.replace(/\\n/g, '') : '')
                        item.listViewSelf = item.listView
                        const set = setList[item.fieldName]

                        if (set && typeof set === 'object') {
                            item.listStipulate = set.listStipulate || 0
                            item.fontSize = set.fontSize
                            item.width = set.width
                            item.listViewSelf = set.listViewSelf
                        }

                        return item
                    })
                this.realColsAfter = this.$help.deepClone(this.realCols)

            }
        },

        computedDialogCols() {
            if (this.cols) {
                const cols = this.$help.deepClone(this.cols)
                cols.forEach(item => {
                    item.listOutQueryView = item.listOutQueryView || '否'
                    if (item.listQueryView === '是' && item.listOutQueryView === '否') {
                        this.$set(item, 'list', item.list ? item.list : [])
                        this.$set(item, 'model', '')
                        this.$set(item, 'viewName', item.viewName ? item.viewName.replace(/\\n/g, '') : '')
                        this.$set(item, 'name', item.name ? item.name.replace(/\\n/g, '') : '')
                        this.$set(item, 'loading', false)
                        let str = null
                        if (item.listQueryModel === this.$variable.searchType.NUM) {
                            str = ['', '']
                        } else {
                            str = ''
                        }
                        this.$set(item, 'searchModel', str)
                        this.$set(item, 'field', item)
                        this.dialog.cols.push(item)
                    }
                })
            }
        },

        searchShow() {
            //弹窗筛选
            // this.dialog.show = true
            //表格左侧筛选
            this.$emit('searchShowLeft')
        },
        selectRemote(item, args, type) {
            if (type === 'focus' && item.list && item.list.length > 0) {
                return
            }
            let param = {}
            console.log('1111', this.$parent, item, args, type)
            if (this.parent && this.parent.$parent.getParam1) {
                param = this.parent.$parent.getParam1()
            } else {
                param = this.$parent.$parent.getParam1()
            }
            delete param.conditionList
            const str = args ? encodeURIComponent(args) : ''
            item.loading = true;
            this.$api.post(`setting/sysTableField/distinctFieldValue?tableName=${this.tableName}&fieldName=${item.fieldName}&keyword=${str}`,
                param)
                .then(res => {
                    item.list = res.data
                })
                .finally(() => {
                    item.loading = false
                })

        },
        leadingOut() {
            this.dialogSave()
            this.$emit('leadingOut')
        },
        dialogSave() {
            this.dialog.show = false
            this.$emit('handleFilter', this.dialog.list)
        },

        showSet() {
            this.set.show = true
            this.$nextTick(() => {
                if (!this.rowDrop.loaded) {
                    this.rowDrop.loaded = true
                    this.rowDrop()
                }
            })
        },
        rowDrop() {
            // const tbody = this.$refs.tableRef.$el.querySelector('.el-table__body-wrapper tbody')
            // const _this = this
            // Sortable.create(tbody, {
            //     onEnd({ newIndex, oldIndex }) {
            //         const currRow = _this.realColsAfter.splice(oldIndex, 1)[0]
            //         _this.realColsAfter.splice(newIndex, 0, currRow)
            //
            //     }
            // })
        },
        setSave() {
            const map = {}
            this.realCols.forEach(item => {
                map[item.fieldName] = {
                    listViewSelf: item.listViewSelf,
                    listStipulate: item.listStipulate,
                    fontSize: item.fontSize || '',
                    width: item.width || '',
                }
            })
            this.$help.store.set(this.sortKey, JSON.stringify(map))
            setTimeout(() => {
                this.$emit('viewChange')
                this.set.show = false
            }, 300)
        },
        setCancel() {
            this.$help.store.remove(this.sortKey)
            this.computedCols()
            this.rowDrop.loaded = false
            this.set.loaded = false

            this.$nextTick(() => {
                this.set.loaded = true

                this.$nextTick(() => {
                    this.rowDrop()
                    setTimeout(() => {
                        this.$emit('viewChange')
                        this.set.show = false
                    }, 300)
                })
            })
        },
    },
}
</script>