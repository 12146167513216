<style lang="scss" scoped>
.search_left {
    width: 250px;
    height: 100%;
    overflow: auto;
    border-right: 10px solid #F3F5F8;
    margin-right: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    .left_top {
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30px;
    }

    .left_middle {
        max-height: calc(100% - 75px);
        overflow: auto;
        margin-bottom: 15px;
        padding-right: 10px;

        &_text {
            font-size: 12px;
            color: #4E5969;
            margin-bottom: 2px;
        }
    }

    .left_bottom {
        text-align: right;
        height: 30px;
    }
}
.el-select-dropdown__item {
    font-size: 12px;
    height: unset;
    line-height: 1.2;
    max-width: 200px;
    white-space: normal;
    padding: 5px 40px 5px 20px;
    word-break: break-all;
}
.el-date-range-picker{
    width: 500px;
}
</style>

<template>
    <div class="search_left">
        <div class="left_top">
            <span style="color: #6684EC;">筛选</span>
            <i class="el-icon-close" style="cursor: pointer;" @click="close"></i>
        </div>
        <div class="left_middle no-scrollbar">
            <div v-for="item in newList" :key="item.id">
                <div class="left_middle_text">{{ item.viewName || item.name }}</div>
                <div style="margin-bottom: 5px;">
                    <!--模糊搜索-->
                    <template v-if="item.listQueryModel === $variable.searchType.LIKE">
                        <el-input v-model="item.model"
                            :placeholder="'请输入' + (item.viewName || item.name || '')"></el-input>
                    </template>
                    <!--精确搜索-->
                    <template v-else-if="item.listQueryModel === $variable.searchType.EQ">
                        <el-input v-model="item.model"
                            :placeholder="'请输入' + (item.viewName || item.name || '')"></el-input>
                    </template>

                    <!--下拉选择性-->
                    <template v-else-if="item.listQueryModel === $variable.searchType.SELECT">
                        <el-select v-model="item.model" :multiple="!item.single" filterable remote style="width:100%"
                            :placeholder="'请选择' + (item.viewName || item.name || '')"
                            @focus="selectRemote(item, undefined, 'focus')" reserve-keyword
                            :remote-method="(argument) => { selectRemote(item, argument) }" :loading="item.loading">
                            <el-option v-for="(child, key) in item.list" :key="key"
                                :value="item.useKey ? child.key : child.value" :label="child.value"></el-option>
                        </el-select>
                    </template>

                    <!--数字范围-->
                    <template v-else-if="item.listQueryModel === $variable.searchType.NUM">
                        <div style="display: flex;align-items: center;">
                            <el-input-number v-model="item.model[0]" :min="0"
                                @change="clickNumber(item.model)"></el-input-number>
                            ~
                            <el-input-number v-model="item.model[1]" :min="item.model[0] || 0"></el-input-number>
                        </div>

                    </template>

                    <!--日期范围-->
                    <template v-else-if="item.listQueryModel === $variable.searchType.DATE">
                        <el-date-picker style="width:100%" v-model="item.model" type="daterange" range-separator="至"
                            value-format="yyyy-MM-dd" unlink-panels></el-date-picker>
                    </template>
                </div>
            </div>

        </div>
        <div class="left_bottom">
            <el-button @click="cancel" plain>清除筛选值</el-button>
            <el-button @click="clickSearch" type="primary">筛选</el-button>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        parent: {},
        cols: {},
        name: {},
        tableName: {},
        searchCols: {
            type: Array,
            default: () => []
        },
        isSelectRemote: {   //是否用自己页面的筛选
            type: Boolean,
            default: false
        },
        isListOutQueryView: {   //是否展示列表外查询条件
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            newList: [],
        }
    },
    created() {
        this.computedDialogCols()
    },
    methods: {
        computedDialogCols() {
            if (this.cols && this.searchCols.length === 0) {
                const cols = this.$help.deepClone(this.cols)
                cols.forEach(item => {
                    item.listOutQueryView = item.listOutQueryView ||'否'
                    const fleg = this.isListOutQueryView  ? item.listOutQueryView === '否'? true : false : true
                    if (item.listQueryView === '是' && fleg) {
                        this.$set(item, 'list', item.list ? item.list : [])
                        // this.$set(item, 'model', '')
                        this.$set(item, 'viewName', item.viewName ? item.viewName.replace(/\\n/g, '') : '')
                        this.$set(item, 'name', item.name ? item.name.replace(/\\n/g, '') : '')
                        this.$set(item, 'loading', false)
                        let str = null

                        if (item.listQueryModel === this.$variable.searchType.NUM) {
                            str = [undefined, undefined]
                        } else {
                            str = ''
                        }
                        this.$set(item, 'model', str)
                        this.$set(item, 'field', item)
                        this.newList.push(item)
                    }
                })
            } else {
                this.newList = [...this.searchCols]
            }
            this.newList = this.newList.sort((a, b) => {
                return a.listSort - b.listSort;
            })

        },
        clickNumber(val) {
            if (val[1] < val[0]) val[1] = val[0]
        },
        selectRemote(item, args, type) {
            if (this.isSelectRemote) return this.$emit('setselectRemote', item, args, type)
            if (type === 'focus' && item.list && item.list.length > 0) {
                return
            }
            let param = {}
            if (this.parent && this.parent.$parent.getParam1) {
                param = this.parent.$parent.getParam1()
            } else {
                param = this.$parent.$parent.getParam1()
            }
            delete param.conditionList
            const str = args ? encodeURIComponent(args) : ''
            item.loading = true;
            this.$api.post(`setting/sysTableField/distinctFieldValue?tableName=${this.tableName}&fieldName=${item.fieldName}&keyword=${str}`,
                param)
                .then(res => {
                    item.list = res.data
                })
                .finally(() => {
                    item.loading = false
                })

        },
        //点击筛选
        clickSearch() {
            console.log('点击了筛选', this.newList);
            this.$emit('handleFilter', this.newList)
            // this.close()
        },
        //关闭弹窗
        close() {
            this.$emit('searchClose', false)
        },
        //清除筛选值
        cancel() {
            this.newList.map(item => {
                if (item.listQueryModel === this.$variable.searchType.NUM) {
                    item.model = [undefined, undefined]
                } else {
                    item.model = ''
                }
                return item
            })
        },
    }
}
</script>