<style lang="scss">
@import "~@/assets/css/var";

.index-page {
  &-notice {
    font-size: 14px;
    color: #ff0000;
    text-align: center;
    width: 800px;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;

    .notice-container {
      width: 100%;
      display: flex;

      &>div {
        display: inline-block;
        margin-left: 30px;
      }
    }

    .topNoticeList_active {
      animation: scroll-left 30s linear infinite;
    }

    .topNoticeList_active:hover {
      animation-play-state: paused;
    }
  }

  @keyframes scroll-left {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  &-search {
    width: 800px;
    margin: 60px auto 0;

    &__tab {
      padding: 0 40px;

      span {
        cursor: pointer;
        display: inline-block;
        text-align: center;
        width: 86px;
        line-height: 38px;
        font-size: 15px;

        &:hover {
          opacity: 0.7;
          color: $main;
        }
      }

      .active {
        background: $main;
        color: #fff !important;
        border-radius: 8px 8px 0 0;
        cursor: unset;
        opacity: 1 !important;
      }
    }

    &__historical {
      .stopHistorical {
        position: absolute;
        right: 20px;
        font-weight: bold;
        cursor: pointer;

        &:hover {
          color: #f11c1c;
          font-weight: bold;
        }
      }
    }

    &__clearAllHistorical {
      text-align: right;
      margin-bottom: -10px;

      &:hover {
        color: #f11c1c;
      }
    }

    .el-autocomplete {
      width: 100%;

      input {
        line-height: 48px;
        height: 48px;
        font-size: 15px;
        padding-left: 70px;
        border-color: $main;
      }

      .el-input__icon {
        padding: 0 10px;
        font-size: 19px;
      }

      .el-input-group__append {
        width: 107px;
        text-align: center;
        background: $main;
        color: #fff;
        border-color: $main;
        font-size: 16px;
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: $main + ee;
        }
      }
    }

    &__lr {
      margin-top: 30px;
      display: flex;
      justify-content: stretch;

      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
        justify-content: space-between;

        img {
          width: 30px;
        }

        span {
          flex: 1;
        }

        .title_top {
          cursor: pointer;
        }

        .title_top:hover {
          color: #4f7af2;
        }
      }

      .back-panel {
        box-sizing: border-box;
        height: 170px;
        border-radius: 10px;
        padding: 5px 25px 10px 12px;
        //box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.05);

        .item {
          position: relative;
          padding-left: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          line-height: 23px;
          cursor: pointer;

          &:before {
            position: absolute;
            content: "";
            width: 6px;
            height: 6px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            border-radius: 50%;
            background: #4c4c4c;
          }

          &:hover {
            color: $main;

            &:before {
              background: $main;
            }
          }
        }
      }

      .hot-item {
        position: relative;
        margin-top: 13px;

        span {
          position: relative;
          display: block;
          line-height: 38px;
          background: #f1f1f1;
          font-size: 14px;
          border-radius: 5px;
          text-align: center;
          color: #323232;
          transition: all 0.3s;
          cursor: pointer;
          padding: 0 8px;

          &:hover {
            color: #fff;
            background: $main;
          }
        }

        i {
          position: absolute;
          top: -10px;
          right: -5px;
          line-height: 22px;
          min-width: 39px;
          padding: 0 5px;
          background: $red;
          color: #fff;
          font-style: normal;
          border-radius: 20px 20px 20px 0;
          font-size: 10px;
          text-align: center;

          &.green {
            background: #0fb158;
          }
        }
      }

      .new-item {
        padding: 0 25px 0 8px;
        margin-top: 45px;

        .search_time {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          margin-bottom: 5px;
        }

        .el-col {
          font-size: 14px;
          line-height: 20px;
          color: #333;
          margin-bottom: 10px;

          p {
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }
          }
        }

        .item {
          display: flex;
          padding-right: 5px;
          cursor: pointer;

          span {
            margin-top: 10px;
          }

          &:hover {
            color: $main;
          }

          i {
            flex-shrink: 0;
            align-self: flex-start;
            line-height: 16px;
            width: 16px;
            text-align: center;
            border: 1px solid #f11c1c;
            border-radius: 4px;
            font-style: normal;
            color: #f11c1c;
            font-size: 12px;
          }

          .text_icon {
            align-self: flex-start;
            font-size: 18px;
          }
        }
      }
    }

    &__left {
      flex: 1;
      flex-shrink: 0;
    }

    &__line {
      width: 1px;
      margin: 0 18px;
    }

    &__right {
      width: 0;
      flex: 1;
      flex-shrink: 0;
    }

    &__hot {
      padding-top: 40px;

      &-title {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 16px;

        span {
          float: right;
          font-size: 12px;
          color: #666;
          cursor: pointer;

          &:hover {
            color: $main;
          }
        }

        i {
          font-size: 14px;
          padding-right: 5px;
        }
      }

      .el-col {
        position: relative;
        padding-bottom: 13px;

        span {
          position: relative;
          display: block;
          line-height: 38px;
          background: #f2f2f2;
          font-size: 14px;
          border-radius: 4px;
          text-align: center;
          color: #333;
          transition: all 0.3s;
          cursor: pointer;
          padding: 0 8px;

          &:hover {
            color: #fff;
            background: $main;
          }
        }

        i {
          position: absolute;
          top: -10px;
          right: -5px;
          line-height: 20px;
          width: 36px;
          background: $red;
          color: #fff;
          font-style: normal;
          border-radius: 10px 10px 10px 0;
          font-size: 12px;
          text-align: center;
        }
      }
    }

    &__new {
      padding-top: 17px;

      .el-col {
        padding-bottom: 19px;
        font-size: 13px;
        line-height: 20px;
        color: #333;

        p {
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }
        }

        i {
          display: inline-block;
          width: 18px;
          line-height: 16px;
          text-align: center;
          border: 1px solid #f11c1c;
          border-radius: 4px;
          font-style: normal;
          color: #f11c1c;
          font-size: 12px;
        }

        &:first-child span {
          color: #e51111;
        }

        &:nth-child(2) span {
          color: #e54d11;
        }

        &:nth-child(3) span {
          color: #496cde;
        }
      }
    }
  }
}

.el-popover {
  .custom-popover__content {
    .desc {
      color: #969697;
      text-align: center;
    }

    .list {
      display: flex;
      padding: 5px;
      font-size: 16px;

      .number {
        color: $main;
      }
    }
  }
}

.el-divider--horizontal {
  margin: 0;
}

.el-autocomplete-suggestion li:last-child:hover {
  background-color: #fff;
}
</style>

<template>
  <div class="index-page">
    <img src="@/assets/img/banner.png" alt="banner">
    <div class="index-page-notice">
      <div class="notice-container topNoticeList_active">
        <div v-for="item in topNoticeList" :key="item.id">
          <i class="el-icon-warning"></i>
          {{ item.content }}
        </div>
      </div>
    </div>
    <div class="index-page-search">

      <div class="index-page-search__tab">
        <span v-for="item in tabs" :key="item.key" :class="{ active: tab === item.key }" @click="tabClick(item)">{{
          item.value }}</span>
        <span @click="goDrugCompare">药品对比</span>
      </div>

      <el-autocomplete ref="autocomplete" v-model="searchTxt" :fetch-suggestions="querySearch"
        @select="selectSearchFunc" :placeholder="myPlaceholder" @keyup.enter.native="searchFunc()">
        <template #prefix>
          <i class="el-input__icon el-icon-search"></i>
          <span style="font-size: 19px">|</span>
        </template>
        <template #append>
          <div @click="searchFunc()">检索</div>
        </template>

        <template v-slot="{ item }">
          <div class="index-page-search__historical" v-if="!item.isOperation">
            <span>{{ item[$variable.indexType.CORP === tab ? 'compName' : 'comName'] }}</span>
            <el-tag style="margin-left: 10px;" v-show="item.accurate" type="success" size="mini"
              disable-transitions>精确</el-tag>
            <span class="stopHistorical" @click.stop="clearHistor(item)"><i class="el-icon-close"></i></span>
          </div>
          <div class="index-page-search__clearAllHistorical" v-else>
            <el-divider></el-divider>
            <i class="el-icon-delete"></i>
            <span @click.stop="clearAll()">全部清除</span>
          </div>
        </template>

      </el-autocomplete>

      <div class="index-page-search__lr">
        <div class="index-page-search__left">
          <div style="box-sizing: border-box;height: 170px">
            <div class="title" v-show="upList.length > 0">
              <div @click="goTextPage('asc')" class="title_top">近期涨价药品</div>
              <div class="clickable" style="font-weight: normal; font-size: 12px;color: #656565;" @click="goUp">
                <i class="el-icon-refresh-right"></i>换一换
              </div>
            </div>
            <div>
              <el-row :gutter="20">
                <el-col v-for="item in upList" :key="item.id" class="hot-item" :span="8" @click.native="goPage(item)">
                  <span class="ellipsis" :title="item.comName">{{ item.comName }}</span>
                  <i>{{ item.upsDownsRate }}%</i>
                </el-col>
              </el-row>
            </div>
            <div class="title" v-show="downList.length > 0" style="margin-top: 8px;">
              <div @click="goTextPage('desc')" class="title_top">近期跌价药品</div>
              <div class="clickable" style="font-weight: normal; font-size: 12px;color: #656565;" @click="goDown">
                <i class="el-icon-refresh-right"></i>换一换
              </div>
            </div>
            <div>
              <el-row :gutter="20">
                <el-col v-for="item in downList" :key="item.id" class="hot-item" :span="8" @click.native="goPage(item)">
                  <span class="ellipsis" :title="item.comName">{{ item.comName }}</span>
                  <i class="green">{{ item.upsDownsRate }}%</i>
                </el-col>
              </el-row>
            </div>
          </div>
          <img class="clickable" style="margin-top: 45px;" src="@/assets/img/index-task.png" alt="" @click="goTask">


          <!--region 最近新药申报-->
          <!-- <div class="new-item">
            <div class="title">
              <span>最近新药申报</span>
              <div class="clickable" style="font-weight: normal; font-size: 12px;color: #656565;" @click="getNew">
                <i class="el-icon-refresh-right" style="margin-right: 5px;"></i>换一换
              </div>
            </div>
            <div class="search_time">
              <convenient-filtering :screenName="screenName" @searchTime="searchTime" :isFinite="false"
                :newScreenList="screenList"></convenient-filtering>
              <div><el-link type="primary" :underline="false" @click="goDrugMore">更多</el-link></div>
            </div>
            <el-row>
              <el-col v-for="item in newList" :key="item.id" :span="8">
                <div class="item" style="justify-content: space-between;">
                  <span class="ellipsis" @click="goNew(item)">{{ item.viewName }}</span>
                  <div style="display: flex;align-items: center;">
                    <i v-if="item.hot">新</i>
                    <div class="el-icon-loading" v-if="starLoading"></div>
                    <template v-else>
                      <div class="el-icon-star-on text_icon" v-if="item.thisUserAttention" @click="removeStar(item)"
                        style="color: #5171DA;"></div>
                      <div class="el-icon-star-off text_icon" v-else @click="addStar(item)"></div>
                    </template>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div> -->
          <!--endregion-->

        </div>
        <div class="index-page-search__line"></div>
        <div class="index-page-search__right">
          <div class="back-panel">
            <div class="title" style="margin-bottom: 8px;">
              <img src="@/assets/img/bell.png" alt="">
              <span>&nbsp;消息中心</span>
              <div class="right">
                <el-link type="primary" :underline="false" @click="seeMoreNotice">更多</el-link>
              </div>
            </div>
            <div class="item" @click="goNotice(item)" v-for="item in noticeList" :key="item.id">{{ item.content }}</div>
          </div>

          <div class="new-item">
            <div class="title">
              <span>最新数据库</span>
              <!-- <span>最近获批新药</span> -->
              <div class="clickable" style="font-weight: normal; font-size: 12px;color: #656565;" @click="getNew">
                <i class="el-icon-refresh-right" style="margin-right: 5px;"></i>换一换
              </div>
            </div>
            <!--region 最近获批新药-->
            <!-- <div class="search_time">
              <convenient-filtering :screenName="screenName" @searchTime="searchTime" :isFinite="false"
                :newScreenList="screenList"></convenient-filtering>
              <div><el-link type="primary" :underline="false" @click="goDrugMore">更多</el-link></div>
            </div> -->
            <!--endregion-->

            <el-row>

              <!--region 最近获批新药-->
              <!-- <el-col v-for="item in newList" :key="item.id" :span="8">
                <div class="item" style="justify-content: space-between;">
                  <span class="ellipsis" @click="goNew(item)">{{ item.viewName }}</span>
                  <div style="display: flex;align-items: center;">
                    <i v-if="item.hot">新</i>
                    <div class="el-icon-loading" v-if="starLoading"></div>
                    <template v-else>
                      <div class="el-icon-star-on text_icon" v-if="item.thisUserAttention" @click="removeStar(item)"
                        style="color: #5171DA;"></div>
                      <div class="el-icon-star-off text_icon" v-else @click="addStar(item)"></div>
                    </template>
                  </div>
                </div>
              </el-col> -->
              <!--endregion-->

              <el-col v-for="item in newList" :key="item.id" :span="8">
                <div class="item">
                  <span class="ellipsis" @click="goNew(item)">{{ item.viewName }}</span>
                  <i v-if="item.hot">热</i>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import help from "@/util/help";

export default {
  data() {
    return {
      visitId: new Date().getTime(),
      pageId: 'index',
      pageName: '首页',

      tab: this.$variable.indexType.DRUG,
      tabs: [
        { key: this.$variable.indexType.DRUG, value: '药品' },
        { key: this.$variable.indexType.CORP, value: '企业' },
        { key: this.$variable.indexType.ASSIST, value: '用药助手' },
      ],
      kindName: '',
      history: [],

      searchTxt: '',

      hotList: [],

      newList: [],
      newCurrentPage: 0,
      newPageTotal: 1,

      // 涨跌的药品列表
      upList: [],
      downList: [],

      // 消息列表
      noticeList: [],
      // 顶部消息列表
      topNoticeList: [],

      starLoading: false,

      screenName: 'createdAt',
      screenList: [
        {
          key: 'oneMonths',
          value: '30天内',
          num: 1,
        },
        {
          key: 'twoMonths',
          value: '60天内',
          num: 2,
        },
        {
          key: 'allData',
          value: '全部',
          num: 0,
        },
      ],

      startFormattedDate: '',
      endFormattedDate: '',
    }
  },
  created() {
    const type = parseInt(this.$route.query.type)
    if ([1, 2, 3].includes(type)) {
      this.tab = type
    }
    this.typeChange(true)


    // 获取热搜关键字
    this.$help.getUrlIndex(0)
      .then(res => {
        this.hotList = res.data.records || []
      })

    this.getNew()

    this.$help.socket.send(this)
    // 获取当前日期
    const currentDate = new Date();

    // 复制当前日期对象，用于计算一个月前的日期
    const oneMonthAgo = new Date(currentDate);
    oneMonthAgo.setMonth(currentDate.getMonth() - 1);

    // 格式化日期为 YYYY-MM-DD
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    // 获取格式化的日期
    this.startFormattedDate = formatDate(oneMonthAgo);
    this.endFormattedDate = formatDate(currentDate);

    this.goDown()
    this.goUp()

    // 获取公告
    this.$api.post('help/HelpNotice/queryHelpNoticeDesktopPage', {
      sort: {
        updatedAt: 'desc'
      },
      page: { pageNo: 1, pageSize: 5 },
    })
      .then(res => {
        if (res.success) {
          this.noticeList = res.data.records || []
        }
      })
    this.getTopNoticeList()
  },
  computed: {
    myPlaceholder() {
      if (this.tab === this.$variable.indexType.DRUG) {
        return '请输入药品关键字'
      } else if (this.tab === this.$variable.indexType.CORP) {
        return '请输入企业关键字'
      } else {
        return '请输入药品关键字'
      }
    },
  },
  methods: {
    getTopNoticeList() {
      this.topNoticeList = []
      // 获取是否展示公告提示
      this.$api.post('help/HelpNotice/queryHelpNoticeDesktopPage', {
        conditionList: [{ name: "isShowPc", op: "eq", value: "YES" }],
        sort: {
          updatedAt: 'desc'
        },
        page: { pageNo: 1, pageSize: 9 },
      })
        .then(res => {
          if (res.success) {
            let startTimeList = []
            let endTimeList = []
            let newDate = new Date().getTime()
            res.data.records.forEach((item) => {
              let startTime = new Date(item.startTime.replace(/ /, 'T')).getTime()
              let endTime = new Date(item.endTime.replace(/ /, 'T')).getTime()
              if (newDate > startTime && newDate < endTime) {
                this.topNoticeList.push(item)
              }
              if (startTime > newDate && startTime < endTime) {
                startTimeList.push(
                  startTime
                )
              } else if (endTime > newDate) {
                endTimeList.push(
                  endTime
                )
              }
            })
            startTimeList = startTimeList.sort((a, b) => a - b)
            endTimeList = endTimeList.sort((a, b) => a - b)
            if (endTimeList && endTimeList.length > 0) {
              let endNum = endTimeList[0] - newDate
              setTimeout(() => {
                this.getTopNoticeList()
              }, endNum)
            }
            if (startTimeList && startTimeList.length > 0) {
              let startNum = startTimeList[0] - newDate
              setTimeout(() => {
                this.getTopNoticeList()
              }, startNum)
            }
          }
        })
    },
    goPage(data) {
      const { href } = this.$router.resolve({
        path: `/bidding-info-country`,
        query: {
          comCode: data.comCode,
          compCode: data.compCode,
          unifySpec: data.unifySpec,
          conversionRatio: data.conversionRatio
        }
      });
      window.open(href, "_blank");
    },
    goTextPage(str) {
      const { href } = this.$router.resolve({
        path: `/bidding-info-country`,
        query: {
          upsDownsRate: str
        }
      });
      window.open(href, "_blank");
    },
    // 获取热搜关键字
    getNew() {
      this.newCurrentPage += 1
      if (this.newCurrentPage > this.newPageTotal) {
        this.newCurrentPage = this.newCurrentPage - this.newPageTotal
      }
      this.$help.getUrlIndex(1, 6, { pageNo: this.newCurrentPage })
        .then(res => {
          this.newList = res.data.records || []
          this.newPageTotal = res.data.totalPage
        })
    },
    tabClick(tab) {
      this.tab = tab.key
      this.typeChange()
    },
    typeChange(isCreated) {
      if (!isCreated) {
        this.txt = ''
      }
      switch (this.tab) {
        case this.$variable.indexType.DRUG:
          this.kindName = 'drug'
          break
        case this.$variable.indexType.CORP:
          this.kindName = 'corp'
          break
        case this.$variable.indexType.ASSIST:
          this.kindName = 'assist'
          break
      }

      this.getHistory()

    },
    getHistory() {
      const str = this.$help.store.get(this.kindName + 'History')
      if (str) {
        const history = str.split(this.$variable.splitStr) ? str.split(this.$variable.splitStr).map(item => {
          const obj = {}
          const arr = item.split(this.$variable.minSplitStr)
          obj[this.$variable.indexType.CORP === this.tab ? 'compName' : 'comName'] = arr[0]
          if (arr.length > 1) {
            obj.accurate = true
            obj.accurateShow = true
            obj.isOperation = false
          }

          return obj
        }) : []
        if (history.length > 10) {
          let historyArr = history.filter(item => item.comName != '' && item.compName != '')
          historyArr.push({
            isOperation: true,
          })
          this.history = history.splice(0, 11)
        } else {
          let historyArr = history.filter(item => item.comName != '' && item.compName != '')
          historyArr.push({
            isOperation: true,
          })
          this.history = historyArr
        }
      } else {
        this.history = []
      }
    },
    setHistory() {
      this.$help.store.set(this.kindName + 'History', this.history
        .map(item => {
          let str = item[this.$variable.indexType.CORP === this.tab ? 'compName' : 'comName']
          if (item.accurate) {
            str += this.$variable.minSplitStr
            str += this.$variable.suffix
          }
          return str
        })
        .join(this.$variable.splitStr)
      )
    },
    clearHistor(recording) {
      console.log(recording, this.history)
      this.history = this.history.filter(item => {
        // 整个数据内容添加了全部删除，作为一个单独的项放在末尾
        // 删除操作首先将单独的操作项去除，再匹配名称不同的，再匹配属于精确搜索还是模糊搜索，一共三项
        let isData = !item.isOperation && item.comName == recording.comName && item.accurate == recording.accurate
        if (!isData) {
          return item
        }
      })


      if (this.history.length <= 1) {
        this.history = []
      }
      // 获取删除后本地搜索记录，进行更新
      this.setHistory()
      // 获取搜索历史
      this.$refs.autocomplete.getData()
    },
    clearAll() {
      this.$help.store.remove(this.kindName + 'History')
      this.history = []
      this.$refs.autocomplete.getData()
    },
    querySearch(queryString, cb) {
      if (queryString) {
        const param = { keyword: queryString }
        switch (this.tab) {
          case this.$variable.indexType.DRUG:
            this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', param).then(res => {
              cb(res.data.map(item => {
                item.accurate = true
                return item
              }));
            });
            break;
          case this.$variable.indexType.CORP:
            this.$api.get('setting/aimBaseComp/queryAimBaseCompListByKeyword', param).then(res => {
              cb(res.data)
            });
            break;
          case this.$variable.indexType.ASSIST:
            this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', param).then(res => {
              cb(res.data);
            });
            break;
        }
      } else {
        cb(this.history)
      }
    },
    selectSearchFunc(item) {

      this.searchTxt = item[this.$variable.indexType.CORP === this.tab ? 'compName' : 'comName']
      this.searchFunc(item.accurate)

    },
    searchFunc(fullFlag) {
      const field = this.$variable.indexType.CORP === this.tab ? 'compName' : 'comName'
      const arr = this.history.map(item => item[field] + (item.accurate ? this.$variable.minSplitStr : ''))
      if (!arr.includes(this.searchTxt + (fullFlag ? this.$variable.minSplitStr : ''))) {
        const obj = {
          accurate: !!fullFlag
        }
        obj[field] = this.searchTxt
        this.history.unshift(obj)
        if (this.searchTxt) {
          this.setHistory(fullFlag)
        }
      }
      let path = `/${this.kindName}/${this.$help.encryption(this.searchTxt)}`

      if (this.kindName === 'drug' && fullFlag) {
        path += `?accurate=1`
      }

      const { href } = this.$router.resolve({
        path,
      });
      window.open(href, "_blank");
    },

    goDrugCompare() {
      const { href } = this.$router.resolve({
        path: '/drug-compare',
      });
      window.open(href, "_blank");
    },

    seeMoreNotice() {
      const { href } = this.$router.resolve({
        path: '/my-notice',
      });
      window.open(href, "_blank")
    },
    goNotice(item) {
      const { href } = this.$router.resolve({
        path: `/my-notice?open=${item.id}`,
      });
      window.open(href, "_blank")
    },

    goTask() {
      if (!help.token.get()) {
        help.goLogin()
        return false
      }

      const { href } = this.$router.resolve({
        path: '/requirement',
      });
      window.open(href, "_blank")
    },
    goNew(item) {
      window.open(item.url)
    },

    goDown() {
      // 获取暴跌，跌价药品
      this.$api.post('setting/aimBaseDrugsWinning/queryWinningPage', {
        conditionList: [
          {
            name: 'priceState',
            op: 'in',
            value: ['暴跌', '跌价'],
          }, {
            name: "winningTime",
            op: "ge",
            value: this.startFormattedDate
          },
          {
            name: "winningTime",
            op: "le",
            value: this.endFormattedDate
          }
        ],
        page: { pageNo: 1, pageSize: 3 },
      })
        .then(res => {
          this.downList = res.data.records || []
        })
    },
    goUp() {
      // 获取暴涨，涨价药品
      this.$api.post('setting/aimBaseDrugsWinning/queryWinningPage', {
        conditionList: [
          {
            name: 'priceState',
            op: 'in',
            value: ['暴涨', '涨价'],
          },
          {
            name: "winningTime",
            op: "ge",
            value: this.startFormattedDate
          },
          {
            name: "winningTime",
            op: "le",
            value: this.endFormattedDate
          }
        ],
        page: { pageNo: 1, pageSize: 3 },
      })
        .then(res => {
          this.upList = res.data.records || []
        })
    },


    // 添加收藏
    addStar(row) {
      if (this.$help.token.get()) {
        this.$set(row, 'starLoading', true)
        this.$api.post('sys/sysUserAttention/insertSysUserAttention', {
          type: 0,
          comCode: row.comCode,
          comName: row.comName,
        })
          .then((res) => {
            if (res.success) {
              this.$api.get(`indexDrug/aimBaseDrugs/queryAimBaseDrugsPageShow?id=${row.id}`)
                .then(res => {
                  row.attentionNumber = row.attentionNumber + 1
                  this.$set(row, 'thisUserAttention', res.data.thisUserAttention)
                })
                .finally(() => {
                  this.$set(row, 'starLoading', false)
                })
            }
          })
          .finally(() => {
            this.$set(row, 'starLoading', false)
          })
      } else {
        this.$help.goLogin()
      }
    },
    // 取消收藏
    removeStar(row) {
      if (this.$help.token.get()) {
        this.$set(row, 'starLoading', true)
        this.$api.post('sys/sysUserAttention/deleteByIdList', [this.corp.thisUserAttention.id])
          .then(res => {
            if (res.success) {
              row.attentionNumber = row.attentionNumber - 1
              row.thisUserAttention = null
            }
          })
          .finally(() => {
            this.$set(row, 'starLoading', false)
          })
      } else {
        this.$help.goLogin()
      }
    },

    //便捷时间筛选
    searchTime(val) {
      let fleg = true
      let cndList = []
      if (val && val.length != 0) {
        for (let index = 0; index < val.length; index++) {
          if (!val[index].value) {
            fleg = false
          }
          if (cndList && cndList.length != 0) {
            cndList.forEach((item, i) => {
              if (item.name === this.screenName) {
                cndList.splice(i, 1)
              }
            })
          }
        }
      } else {
        fleg = false
      }
      cndList = !fleg ? cndList : cndList.concat(val)
      console.log('时间筛选', cndList);
      // this.init()
    },
    //点击跳转更多
    goDrugMore() {
      console.log('点击跳转更多');
      // const { href } = this.$router.resolve({
      //   path: '/my-notice',
      // });
      // window.open(href, "_blank")
    }
  }
}
</script>