<template>
    <div class="common-table search-result">
        <div class="common-table__title">按企业分析
            <div class="flex-item"></div>
            <div class="ts-search">
                <span style="font-size: 14px;">筛选：</span>
                <el-select v-model="compCode" filterable remote reserve-keyword placeholder="请输入药企关键词" :remote-method="remoteMethod"
                           :loading="selectLoading" style="width: 300px" @change="changeFun">
                    <el-option v-for="item in options" :key="item.id" :label="item.compName" :value="item.compCode"></el-option>
                </el-select>
            </div>
        </div>
        <div v-if="isEmpty" class="search-result__container" style="margin-top: 20px;padding-top: 100px;">
            <ts-empty></ts-empty>
        </div>
        <div v-else class="search-result-body">
            <el-tabs v-model="tab" type="border-card">
                <el-tab-pane name="year"><span slot="label">{{ pageMap.year }}</span></el-tab-pane>
                <el-tab-pane name="yearOne"><span slot="label">{{ pageMap.yearOne }}</span></el-tab-pane>
                <el-tab-pane name="quarter"><span slot="label">{{ pageMap.quarter }}</span></el-tab-pane>
                <el-tab-pane name="ratio"><span slot="label">{{ pageMap.ratio }}</span></el-tab-pane>
                <el-tab-pane name="bid"><span slot="label">{{ pageMap.bid }}</span></el-tab-pane>
            </el-tabs>
        </div>

        <div class="search-result__container" v-if="loaded && !isEmpty">
            <div class="search-result__panel" :class="{show: tab === 'year'}">
                <corp-year v-if="finished.year" :comp-code="compCode" :visit-id="visitId"></corp-year>
            </div>
            <div class="search-result__panel" :class="{show: tab === 'yearOne'}">
                <corp-year-one v-if="finished.yearOne" :comp-code="compCode" :visit-id="visitId" :base-query="{compCode: compCode}"></corp-year-one>
            </div>
            <div class="search-result__panel" :class="{show: tab === 'quarter'}">
                <corp-quarter v-if="finished.quarter" :comp-code="compCode" :visit-id="visitId" :base-query="{compCode: compCode}"></corp-quarter>
            </div>
            <div class="search-result__panel" :class="{show: tab === 'bid'}">
                <corp-bid v-if="finished.bid" :comp-code="compCode" :visit-id="visitId"></corp-bid>
            </div>
            <div class="search-result__panel" :class="{show: tab === 'ratio'}">
                <corp-ratio v-if="finished.ratio" :comp-code="compCode" :visit-id="visitId" :base-query="{compCode: compCode}"></corp-ratio>
            </div>
        </div>
    </div>
</template>

<script>
    import CorpBid from './CorpBid'
    import CorpQuarter from './CorpQuarter'
    import CorpYear from './CorpYear'
    import CorpYearOne from './CorpYearOne'
    import CorpRatio from './CorpRatio'
    export default {
        components: {CorpBid, CorpQuarter,CorpYear,CorpYearOne, CorpRatio},
        data() {
            return {
                visitId: 0,
                pageId: '',
                pageName: '',
                pageMap: {
                    year: '企业历年药品销售趋势',
                    yearOne: '企业历年单品销售',
                    quarter: '企业历年季度销售趋势',
                    ratio: '产品销售比重',
                    bid: '企业产品历年中标价格表',
                },

                tab: 'year',
                loaded: false,
                finished: {
                    year: false,
                    quarter: false,
                    bid: false,
                },
                isEmpty: false,

                compName: '',
                compCode: '',
                selectLoading: false,
                options: [],
            }
        },
        created() {
            this.compCode = this.$route.query.compCode
            this.init()
        },
        watch: {
            '$route': function () {
                this.init()
            },
            tab: {
                handler(val) {
                    this.finished[val] = true

                    this.visitId = new Date().getTime()
                    this.pageId = val
                    this.pageName = '企业分析-' + this.pageMap[val]

                    this.$help.socket.send(this)
                },
                immediate: true
            },
        },
        methods: {
            init() {
                this.isEmpty = false
                this.remoteMethod(this.$route.query.compCode || '四川科伦药业股份')
            },
            remoteMethod(keyword) {
                this.$api.get('setting/aimBaseComp/queryAimBaseCompListByKeyword', {keyword}).then(res => {
                    this.options = res.data
                    if (!this.compCode) {
                        this.compCode = 'YQ000546'
                        // this.compCode = 'QY1000015997'
                        // this.compName = '四川科伦药业股份有限公司'
                    }
                    this.loaded = true
                });
            },
            changeFun() {
                this.loaded = false
                this.$nextTick(() => {
                    this.loaded = true
                })
            },
        },
    }
</script>