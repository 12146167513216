<!-- 省份销售数据库 -->
<style lang="scss">
@import "~@/assets/css/var";

.my-star {
    .ts-table {
        display: flex;
        flex: 1;
        background: #fff;
        padding-right: 10px;
    }

    .my-table {
        width: 100%;
        padding: 20px 10px;
    }
}

.txt_btn {
    color: #666;
    font-size: 14px;
    margin-right: 20px;
    float: right;

    &:hover {
        color: $main;
    }
}
</style>

<template>
    <div class="common-table my-star">
        <div class="common-table__title">
            省份销售数据库
        </div>
        <div v-if="!$help.token.get()" class="add">
            <ts-error></ts-error>
        </div>
        <div class="ts-table" v-else>
            <ts-table class="make-table" style="flex: 3" ref="datatable" :cols="cols" hide-border :data="list"
                :page-param="pageParam" :Checkbox="true" :loading="loading" height="60vh" :cmds="cmds"
                @handleSizeChange="handleSizeChange" @handleSort="sort" @handleCurrentChange="handleCurrentChange"
                :hidePage="false">
                <template slot="left">
                    <el-button icon="el-icon-refresh" @click="init" type="primary" plain>刷新</el-button>
                    <el-button icon="el-icon-plus" type="success" plain @click="addList()">新增</el-button>
                    <el-button icon="el-icon-edit" type="success" plain @click="editList()">修改</el-button>
                    <el-button icon="el-icon-close" type="danger" plain @click="deleteId()">删除</el-button>
                </template>
                <template slot="right">
                    <div>
                        （单位：元）
                    </div>
                </template>
                <template v-slot:slot_attachment="{ props }">
                    <div class="comment-attachment">
                        <el-link style="margin-left: 10px;" :underline="false" v-if="props.row.attachment"
                            type="primary" :href="props.row.attachment" target="_blank">查看文件</el-link>
                    </div>
                </template>
            </ts-table>
        </div>
        <add-edit :open="open" :cols="cols" :clickLoading="clickLoading" :title="title" :editPrame="editPrame"
            :userUrl="userUrl"></add-edit>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
import variable from "@/util/variable";
import AddEdit from '@/components/AddEdit';

export default {
    components: {
        AddEdit
    },
    mixins: [myMixin],
    data() {
        return {
            //修改或者确定的接口
            userUrl: '',
            //修改的数据
            editPrame: {},
            //筛选
            dialog: {
                show: false,
                cols: [],
                list: [
                    {
                        model: '',
                        field: {}
                    },
                ],
            },
            //新增开关
            open: false,
            title: '',
            visitId: new Date().getTime(),
            cmds: [],
            loading: false,
            pageName: '省份销售数据库',
            pageParam: {
                pageNo: 1,
                pageSize: variable.basePageSize,
                total: 0,
                error: '',
            },
            currentType: '',
            conditionList: [],
            list: [],
            sortParam: {},
            //operateType：  select下拉，number数值,string字符串,date时间
            cols: this.$help.generalCols([
                {
                    fieldName: 'saleYear',
                    name: '销售年份',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc',
                    listOrderView: '是',
                    operateType: 'year',
                    onDisplay: true,
                    onRequired: true,
                },
                {
                    fieldName: 'provinceName',
                    name: '省份',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listOrderView: '是',
                    operateType: 'select',
                    onDisplay: true,
                    onRequired: true,
                },
                {
                    fieldName: 'compName',
                    name: '生产企业',
                    width: 200,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listOrderView: '是',
                    operateType: 'select',
                    onDisplay: true,
                    onRequired: true,
                    param: {
                        stopKey: 'comCode', //现有了comCode才能选企业
                        placeholder: "请先选择药品"
                    }
                },
                {
                    fieldName: 'comName',
                    name: '药品通用名',
                    width: 120,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listOrderView: '是',
                    operateType: 'select',
                    onDisplay: true,
                    onRequired: true,
                },
                {
                    fieldName: 'unifySpec',
                    name: '规格',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listClass: 'tc',
                    listOrderView: '是',
                    operateType: 'string',
                    onDisplay: true,
                },
                {
                    fieldName: 'packSpec',
                    name: '包装规格',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc',
                    listOrderView: '是',
                    operateType: 'string',
                    onDisplay: true,
                },
                {
                    fieldName: 'unifyDose',
                    name: '剂型',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listClass: 'tc',
                    listOrderView: '是',
                    operateType: 'string',
                    onDisplay: true,
                },
                {
                    fieldName: 'price',
                    name: '单价',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc',
                    listOrderView: '是',
                    operateType: 'number',
                    onDisplay: false,
                },
                {
                    fieldName: 'quantity',
                    name: '销售数量',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc',
                    listOrderView: '是',
                    operateType: 'number',
                    onDisplay: true,
                },
                {
                    fieldName: 'amount',
                    name: '销售额',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc',
                    listOrderView: '是',
                    operateType: 'number',
                    onDisplay: true,
                },
                {
                    fieldName: 'attachment',
                    key: 'attachment',
                    toFileType: '文件',
                    name: '文件',
                    width: 80,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc',
                    listOrderView: '否',
                    operateType: 'file',
                    onDisplay: true,
                },
                {
                    fieldName: 'statusName',
                    name: '处理状态名称',
                    width: 100,
                    // listQueryView: '是',
                    // listQueryModel: 1,
                    // listClass: 'tc',
                    operateType: 'string',
                    noBatch: true,
                    // listOrderView: '是',
                    onDisplay: false,
                },
            ]),
        }
    },
    created() {
        this.init()
        this.$help.socket.send(this)
    },
    methods: {
        //筛选按钮
        searchShow() {
            this.conditionList = []
            this.dialog.show = true
        },
        //新增/修改关闭弹窗
        clickLoading(arr, odd) {
            if (odd) {
                this.init()
            }
            this.open = arr
        },
        //新增按钮
        addList() {
            this.open = true
            this.title = '新增'
            this.userUrl = 'sys/saleUserProvince/insertSaleUserProvince'
        },
        //修改按钮
        editList() {
            const orrList = this.$refs.datatable.$refs.table.selection
            if (orrList.length === 1) {
                if (orrList[0].status != '01') {
                    return this.$message({
                        showClose: true,
                        message: '请选择待处理数据，再执行该操作。',
                        type: 'error'
                    });
                }
                this.open = true
                this.title = '修改'
                if (orrList[0].saleYear) orrList[0].saleYear = orrList[0].saleYear.toString()
                this.editPrame = this.$help.deepClone(orrList[0])
                this.userUrl = 'sys/saleUserProvince/updateSaleUserProvince'
            } else {
                this.$message({
                    showClose: true,
                    message: '请先选择有效记录，再执行该操作。',
                    type: 'error'
                });
            }
        },
        //删除按钮
        deleteId() {
            const orrList = this.$refs.datatable.$refs.table.selection
            if (orrList.length === 1) {
                if (orrList[0].status != '01') {
                    return this.$message({
                        showClose: true,
                        message: '请选择待处理数据，再执行该操作。',
                        type: 'error'
                    });
                }
                this.$confirm(`确定删除${orrList.length}条记录吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$api.delete(`sale/saleUserProvince/deleteById?id=${orrList[0].id}`)
                        .then(() => {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        })
                        .finally(() => {
                            this.init()
                            this.loading = false
                        })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    showClose: true,
                    message: '请先选择有效记录，再执行该操作。',
                    type: 'error'
                });
            }
        },
        init() {
            this.pageParam.pageNo = 1
            this.getList()
        },
        sort(param) {
            this.sortParam = {}
            if (param) {
                this.sortParam = param
            }
            this.init()
        },
        getList() {
            this.loading = true
            const param = {
                params: {
                    type: this.currentType || undefined
                },
                conditionList: this.conditionList,
                page: this.pageParam,
                sort: this.sortParam,
            }
            if (JSON.stringify(param.sort) === '{}') {
                param.sort = { saleYear: "desc" }
            }
            this.$api.post('sale/saleUserProvince/querySaleUserProvincePage', param, { 'Visit-Id': this.$help.getVisitId(this.visitId) })
                .then(res => {
                    if (res.success) {
                        this.pageParam.total = res.data.totalCount
                        this.list = res.data.records
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        handleSizeChange(val) {
            this.pageParam.pageSize = val
            this.handleCurrentChange(1)
        },
        handleCurrentChange(page) {
            this.pageParam.pageNo = page
            this.getList()
        },
    }
}
</script>